/**
 * This file contains constraints that can be used with
 * validate.js to validate forms
 */
const validate = require('validate.js');

const regex = {
  name: /^[a-zA-Z-]+(\s[a-zA-Z-]+)+$/,
  password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$/,
  phone: /^\+[1-9]\d{1,14}$/,
  loggerSerial: /^PM[1-7]S\d{4}$/,
};

/**
 * A name must be only alphabetical characters, spaces, and dashes
 */
validate.validators.name = function nameValidator(val) {
  if (regex.name.test(val)) {
    return null;
  }
  return 'Name does not match formatting requirements';
};

/**
 * Password must be between 8 and 64 characters long, must contain both upper
 *  and lowercase characters + at least one number
 */
validate.validators.password = function passwordValidator(val) {
  if (regex.password.test(val)) {
    return null;
  }
  return 'Password doesn\'t meet requirements';
};

/**
 * Phone number must start with a + and then only contain valid numbers
 */
validate.validators.phone = function phoneValidator(val) {
  if (regex.phone.test(val)) {
    return null;
  }
  return 'Phone number must start with + and contain only numbers';
};

validate.validators.loggerSerial = function loggerSerialValidator(val) {
  if (regex.loggerSerial.test(val)) {
    return null;
  }
  return 'Logger Serial Number must be in the form PMXSXXXX';
};

const constraints = {
  // User form validations
  user: {
    // Register Form
    register: {
      name: {
        presence: true,
        name: true,
      },
      email: {
        presence: true,
        email: true,
      },
      phone: {
        presence: true,
      },
      company: {
        presence: true,
      },
      password: {
        presence: true,
        password: true,
      },
      passwordConf: {
        presence: true,
        password: true,
        equality: 'password',
      },
    },
    // Password reset form
    passwordReset: {
      password: {
        presence: true,
        password: true,
      },
      passwordConf: {
        presence: true,
        password: true,
        equality: 'password',
      },
    },
    // User details edit form
    updateDetails: {
      company: {
        presence: true,
        type: 'string',
      },
    },
  },
  oem: {
    loggerSubscription: {
      serial: {
        presence: true,
        loggerSerial: true,
      },
      quota: {
        numericality: {
          onlyInteger: true,
          greaterThan: 0,
        },
      },
      start: {
        presence: true,
        type: 'string',
      },
      end: {
        type: 'string',
      },
    },
  },
  logger: {
    claim: {
      serial: {
        presence: true,
        loggerSerial: true,
      },
      access_key: {
        presence: true,
        type: 'string',
      },
    },
  },
};

export default {
  validate,
  constraints,
  regex,
};
